$tt_blue: #2399d4;
$signin_overlay: #0369a1;
$heading_blue: #12487d;
$tt_gray: #ccc;
$tt_deep_gray: #a7a7a7;
$tt_white: #fff;
$tt_black: #353636;
$tt_orange: #fb923c;
$tt_dark_orange: #ea580c;
$tt_strong_orange: #fa6400;
