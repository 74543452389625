@import 'colors';
@import 'ngx-toastr/toastr';
@import '@angular/cdk/overlay-prebuilt.css';
@import '@saffron/core-styles/index.css';
@import '@saffron/core-styles/font-awesome.css';
@import url('https://static2.sharepointonline.com/files/fabric/office-ui-fabric-js/1.4.0/css/fabric.min.css');
@import url('https://static2.sharepointonline.com/files/fabric/office-ui-fabric-js/1.4.0/css/fabric.components.min.css');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  background: #fff;
}

main {
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}
.d-inline-block {
  display: inline-block !important;
}
.pos-relative {
  position: relative;
}
.text-center {
  text-align: center !important;
}
.img-fluid {
  max-width: 100%;
}
.flex-col-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

input[type='text'],
input[type='password'] {
  height: 1.25rem;
  border: 1px solid $tt_gray;
  border-radius: 0.25rem;
  padding: 0.25rem;
  margin: 0.25rem;
}

p {
  font-weight: 300;
  font-size: 0.75rem;
}

.ms-Grid {
  margin: 0;
  padding: 0;
}

.owl-carousel.owl-theme {
  .owl-dots {
    .owl-dot {
      span {
        background: $tt_gray;
      }
    }
    .owl-dot.active {
      span {
        background: $tt_dark_orange;
      }
    }
  }
}
.ms-PersonaCard {
  box-shadow: none !important;
}

.readMoreBtn {
  color: var(--color-anchor-default, #006fc4);

  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  line-height: 144%;
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 5px;
  width: 65px;
}
app-home-page {
  height: 100%;
}

.removed {
  background: var(--saf-color-red-100);
  border-bottom: 2px solid var(--saf-color-red-300);
}

.removed::before {
  background: var(--saf-color-red-300);
  border-color: var(--saf-color-red-300);
  border-bottom: 3px solid var(--saf-color-red-300);
}

.added {
  background: var(--saf-color-green-100);
  border-bottom: 2px solid var(--saf-color-green-300);
}

.added::before {
  background: var(--saf-color-green-300);
  border-color: var(--saf-color-green-300);
  border-bottom: 3px solid var(--saf-color-green-300);
}

.redline {
  color: var(--saf-color-red-400);
  text-decoration-line: underline;
}
